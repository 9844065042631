import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContenta from '../components/ServiceDetailsa/ServiceDetailsContenta'
import RelatedServicesa from '../components/ServiceDetailsa/RelatedServicesa'

const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Lead to Cash Order"
                homePageText="Home"
                homePageUrl="/"
                activePageText="Service Details"
            />
            <ServiceDetailsContenta />
            <RelatedServicesa />
            <Footer />
        </Layout>
    );
}

export default Details