import React from 'react'
import ServiceSidebara from './ServiceSidebara'
import details1a from '../../assets/images/services/services-details1a.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContenta = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1a} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Lead to Cash Order</span>
                            <h3>About this Services</h3>
                            <p>Many businesses don't realize that some of their most pressing execution challenges can be addressed by how you can turn your prospects into lifetime customers. Our Intelligent solution can put an end to data silos that can slow sales revenue, inhibit cash flow and provide less-than-ideal customer experiences.</p>
                            <p>Our Lead to Cash Order service provides you as the first touchpoint with a potential consumer to the last step when the invoice is generated a complete end-to-end customer experience. It is imperative from a consumer perspective to be quick in response whether a quote or negotiation is required</p>
                            <p>Now to explain the whole process. Firstly, there is the lead which is generated through marketing campaigns that give custom solutions to consumers. Second is the opportunity to see the willingness of the individual to become a potential customer. Once potential is ascertained, a quote can be made on the page. The quote when accepted becomes a customer order. Here, order management can take place where customer feedback is obtained about the overall process. Finally, there is the cash step, when payment occurs after fulfillment and invoice generation.</p>
                            <p>This Service covers your entire business process chain from initial contact with a prospective customer, to order fulfillment and service delivery. Lead to Cash Process has five sub-processes are:</p>
                            <ul>
                                <li>Contact to Lead</li>
                                <li>Lead to Opportunity</li>
                                <li>Opportunity to Quote/Cart</li>
                                <li>Quote to Order </li>
                                <li>Order to Cash</li>
                                
                            </ul>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Scalable Solution</li>
                                            <li>Improved Productivity</li>
                                            <li>Rich User Experience</li>
                                            <li>Customer Fulfillment</li>
                                            <li>Enable new business models</li>
                                            <li>Innovate through flexibility and agility</li>
                                            <li>A seamless experience for end customers and end-users</li>
                                            <li>A seamless experience for end customers and end-users</li>
                                            <li>Intelligent Suite</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>When processes are automated and simplified, response time automatically becomes efficient. Promotions, retail, and delivery all are streamlined. Real-time updates are shared across the network, which can further help gain insights into consumer purchase behavior. You can turn prospects into lifetime customers by implementing this service. By using this service, you can maximize the value of every customer interaction. improve your back, middle, and front office processes with advanced technologies, having full visibility across the sales process enables strategic end-to-end business value that helps keep the payment cycle moving forward, speeding time to close while keeping cash flow in check. </p>
                            <h3>Application Areas</h3>
                            <p>Related Industries/Application Domains</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Banking Sector
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Insurance Companies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Information and technologies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Utilities
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Chemical
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                       Automotive
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Pharmaceuticals
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Retail
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Oil and Energy
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Sales & Marketing Application Domain
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>Marketing Cloud</span></li>
                                <li><span>Commerce Cloud</span></li>
                                <li><span>Customer Data Cloud</span></li>
                                <li><span>Sales and Service Cloud</span></li>
                                
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebara />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContenta